@use '@angular/material' as mat;

@import 'vars.scss';
@import './styles/pace.scss';
@import '../node_modules/flag-icons/css/flag-icons.min.css';
@import './styles/am-components/am-radio-button';
@import './styles/am-components/am-progress-bar';
@import './styles/am-components/am-menu';
@import './styles/am-components/am-sort-header';
@import './styles/am-components/am-expansion-panel';
@import './styles/am-components/am-tabs';
@import './styles/am-components/am-select';
@import './styles/am-components/am-card';
@import './styles/am-components/am-chips';
@import './styles/am-components/am-paginator';
@import './styles/am-components/am-input';
@import './styles/am-components/am-table';
@import './styles/am-components/am-dialog';
@import './styles/am-components/am-button';
@import './styles/am-components/am-snackbar';
@import './styles/am-components/am-form-field';
@import './styles/am-components/am-datepicker';
@import './styles/am-components/am-tooltip';
@import './styles/am-components/am-checkbox';
@import './styles/am-components/am-button-toggle';
@import './styles/am-components/am-slide-toggle';
@import './styles/am-components/am-autocomplete';

@include mat.core();

$waltio-primary: mat.define-palette($waltio-primary-palette);
$waltio-accent: mat.define-palette($waltio-primary-palette);
$waltio-warn: mat.define-palette($waltio-warn-palette);
$waltio-typography: mat.define-typography-config(
  $font-family: 'Roobert',
  $headline-1:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-5:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-6:
    mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 20px,
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 20px,
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 20px,
    ),
  $body-1:
    mat.define-typography-level(
      $font-size: 14px,
    ),
  $body-2:
    mat.define-typography-level(
      $font-size: 14px,
    ),
  $caption:
    mat.define-typography-level(
      $font-size: 12px,
    ),
  $button:
    mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 14px,
    ),
);

$waltio-theme: mat.define-light-theme(
  (
    color: (
      primary: $waltio-primary,
      accent: $waltio-accent,
      warn: $waltio-warn,
    ),
    typography: $waltio-typography,
  )
);
@include mat.checkbox-density(-1);
@include mat.typography-hierarchy($waltio-typography);
@include mat.all-component-themes($waltio-theme);

* {
  box-sizing: border-box;
  line-height: normal;
  margin: 0;
  padding: 0;
  font-feature-settings: 'liga' off;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  font-size: 100%;
}

h1 {
  margin: 0 !important;
  color: $grey-1;
}

h2,
h3,
h4 {
  margin: 0 !important;
  color: $grey-2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.body-14-bold {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 14px;
  color: $grey-2;
}

.body-14-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 14px;
  color: $grey-2;
}

.body-14 {
  font-family: 'Roobert', sans-serif;
  font-size: 14px;
  line-height: 140%;
  color: $grey-2;
}

.body-13-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 13px;
  color: $grey-2;
}

.body-13 {
  font-family: 'Roobert', sans-serif;
  font-size: 13px;
  color: $grey-2;
}

.body-12-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 12px;
  color: $grey-2;
}

.body-12 {
  font-family: 'Roobert', sans-serif;
  font-size: 12px;
  color: $grey-2;
}

.mat-typography {
  p {
    margin: 0;
  }
}

.pointer {
  cursor: pointer;
}

.error {
  display: inline-block;
  color: $warn-color;
  margin-top: 8px;
}

.error-color {
  color: $warn-color;
  margin: 0 4px;
}

.bold-text {
  font-family: 'Roobert-SemiBold', sans-serif;
}

a {
  color: $primary-color;
}

.custom-textarea {
  width: 100%;
  border-radius: 7px;
  border: 1px solid $border-default;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  resize: none;
  font-family: inherit;
  color: $grey-3;

  &:focus {
    outline: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

// Animations

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  color: #828384;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@mixin set-margin($vertical-margin, $horizontal-margin) {
  margin: $vertical-margin $horizontal-margin;
}

.margin-12 {
  @include set-margin(12px, 12px);
}

.margin-36-auto {
  @include set-margin(36px, auto);
}

.margin-0-auto {
  @include set-margin(0, auto);
}

.margin-0 {
  @include set-margin(0, 0);
}

@mixin set-width($width) {
  width: $width;
}

.width-100 {
  @include set-width(100%);
}

.width-95 {
  @include set-width(95%);
}

.width-90 {
  @include set-width(90%);
}

.width-80 {
  @include set-width(80%);
}

.width-75 {
  @include set-width(75%);
}

.width-70 {
  @include set-width(70%);
}

.width-60 {
  @include set-width(60%);
}

.width-50 {
  @include set-width(50%);
}

.width-55 {
  @include set-width(55%);
}

.width-40 {
  @include set-width(40%);
}

.width-30 {
  @include set-width(30%);
}

.width-25 {
  @include set-width(25%);
}

.width-20 {
  @include set-width(20%);
}

.width-8 {
  @include set-width(8%);
}

.f-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.f-row-end {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.f-row-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.f-column {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.popover {
  background: #363655;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  padding: 8px 16px;
  z-index: 5;

  &--small {
    // max-width: 150px;
  }

  &--large {
    // max-width: 300px;
  }
}

// Chips
.success-chip {
  background: rgba(31, 211, 173, 0.15);
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
  font-family: 'Roobert-Medium', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #00a985;
}

.info-chip {
  background: rgba(26, 26, 225, 0.1);
  border-radius: 4px;
  padding: 4px 8px;

  font-family: 'Roobert-Medium', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: $primary-color;
}

.info-button {
  display: inline-block;
  cursor: pointer;
  width: 13px;
  height: 13px;

  background: url('/assets/img/icons/info-button.svg') no-repeat;

  &:hover {
    background: url('/assets/img/icons/info-button-active.svg') no-repeat;
  }
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.label {
  border-radius: 3px;
  font-size: 12px;
  padding: 6px;

  &--success {
    background: $background-success;
    color: $text-success;
  }

  &--warning {
    background: $background-warning;
    color: $text-warning;
  }

  &--error {
    background: $background-error-label;
    color: $text-error-label;
  }
}

.invisible {
  display: none !important;
}

.account-type {
  width: fit-content;
  border-radius: 4px;
  outline: 1px solid $background-grey-3;
  padding: 5px 7px;

  &:hover {
    cursor: pointer;
    outline: 3px solid rgba(80, 59, 255, 0.3);
  }
}

.account-type--default {
  width: fit-content;
  border-radius: 4px;
  outline: 1px solid $background-grey-3;
  padding: 5px 7px;
}

.actions {
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: $background-grey;
  }

  &--active {
    background: rgba(80, 59, 255, 0.1);
  }
}

.badge {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background: #503bff;
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 12px;
  color: white;
  text-align: center;
}

.accounts-count {
  color: $primary-color;
  padding: 2px 6px;
  border-radius: 4px;
  background: #f1efff;
}
