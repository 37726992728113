@import '../../vars.scss';

div.mat-mdc-autocomplete-panel {
  max-height: 348px !important;
}

.mat-mdc-autocomplete-panel.custom-autocomplete {
  padding: 0;
  margin-top: 8px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px 0px rgba(17, 16, 33, 0.18);

  .autocomplete-title {
    display: flex;
    justify-content: space-between;
    padding: 14px;

    p.body-14-med {
      color: $grey-3;
    }
  }

  .mat-mdc-option {
    padding: 14px;

    .mdc-list-item__primary-text {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 4px;
      margin: 0;
      flex: 1;
    }

    &:hover:not(.mdc-list-item--disabled) {
      background-color: $background-grey;
    }

    label {
      display: flex;
      flex-flow: row nowrap;
      column-gap: 4px;
    }

    .valid-icon {
      display: none;
    }

    &--selected {
      background-color: $background-success;

      .valid-icon {
        display: block;
      }
    }

    &--delete {
      background-color: $background-error;

      label {
        display: flex;
        flex-flow: row nowrap;
        column-gap: 4px;

        p {
          color: $text-error-label;
        }
      }

      &:hover {
        background: $background-error-label !important;
      }
    }

    &--article {
      background: white !important;
      padding: 28px;

      &.mdc-list-item {
        justify-content: center;
      }
    }
  }
}
