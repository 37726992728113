.mat-mdc-card-header {
  padding: 16px !important;
}

.mat-mdc-card.custom-card {
  border: 1px solid #eceff1;
  box-shadow: 0px 1px 2px rgba(5, 7, 10, 0.08);

  .mat-mdc-card-header {
    padding: 32px 24px;
  }

  .mat-mdc-card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #05070a;
    margin: 0;
  }

  .mat-mdc-card-content {
    padding: 24px;
  }
}

//*********************
// CUSTOMIZE SPECIFIC CARD
//*********************

// COMPONENT: onboarding-page
.mat-mdc-card.onboarding-card {
  box-sizing: border-box;
  width: 800px;
  padding-top: 58px;
  padding-bottom: 58px;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.onboarding-card.mobile-card {
  flex: 1;
  border-radius: 0;
  width: 100vw;
}
