.mdc-button {
  padding: 14px 20px;

  // Bulk component
  &.custom-button-bulk-toolbar {
    border-radius: 7px;
    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $grey-2;
    }
  }

  // Primary
  &.mat-primary {
    border-radius: 7px;
    border: 1px solid #3b24fc;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid #1400bd;
      background: radial-gradient(135.14% 135.14% at 50% 0%, #503bff 0%, #2b14e8 100%);
    }

    &:focus {
      border: 1px solid #1400bd;
      background: radial-gradient(135.14% 135.14% at 50% 0%, #503bff 0%, #2b14e8 100%);
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      border: 1px solid #c9cad4;
      background: $background-grey-2;
      color: $grey-3;
    }
  }

  // Primary flash
  &.mat-primary-flash {
    border-radius: 7px;
    border: 1px solid $primary-color;
    background: radial-gradient(50% 100% at 50% 100%, #9453ff 0%, $primary-color 100%);
    box-shadow: 0px 1px 5px 0px rgba(80, 59, 255, 0.2);
    color: white !important;
  }

  // Warn
  &.mat-warn {
    border-radius: 7px;
    border: 1px solid #d30101;

    &:hover {
      border: 1px solid #930000;
      background: radial-gradient(121.62% 121.62% at 50% 0%, #ef2e39 0%, #d11823 100%);
    }

    &:focus {
      border: 1px solid #930000;
      background: radial-gradient(121.62% 121.62% at 50% 0%, #ef2e39 0%, #d11823 100%);
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      color: white;
      background: #ef2e39;
    }
  }

  // Secondary
  &.mat-secondary {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white;
    }
  }

  // Secondary warn
  &.mat-secondary-warn {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      color: $warn-color;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white;
    }
  }

  // Secondary Round
  &.mat-secondary-round {
    border-radius: 37px;
    border: 1px solid #c9cad4;
    background: white !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 2px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white !important;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white !important;
    }
  }

  // Secondary Flash
  &.mat-secondary-flash {
    border-radius: 7px;
    border: 1px solid #c9cad4;
    background: white !important;
    color: $primary-color !important;

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 4px;
    }

    &:hover {
      border: 1px solid #adaeb7;
      background: white !important;
    }

    &:focus {
      border: 1px solid #adaeb7;
      outline: 4px solid $stroke-outline;
    }

    &:disabled {
      opacity: 0.5;
      background: white !important;
    }
  }

  // Sync
  &.mat-sync {
    border-radius: 7px;
    background: #0052ff !important;
    color: white !important;

    &:hover {
      background: #0042ce;
    }

    .mdc-button__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 6px;
    }
  }
}

// .mat-mdc-button.mat-delete {
//   color: white;
// }

// .mat-mdc-button.mat-active {
//   color: rgba(26, 27, 234, 0.0750929);
// }
// .mat-mdc-button.mat-complete {
//   color: $primary-color;
// }
// .mat-mdc-button.mat-mdc-form-field-error {
//   color: blue;
// }
// .mat-mdc-button.mat-default {
//   color: rgba(166, 172, 185, 0.121558);
// }

// .mat-mdc-unelevated-button.mat-primary {
//   border-radius: 22.5px;

//   font-weight: normal;
// }

// .mat-mdc-unelevated-button.mat-warn {
//   border-radius: 22.5px;

//   font-weight: normal;
// }

// .mat-mdc-unelevated-button.mat-active {
//   border-radius: 22.5px;

//   font-weight: normal;
//   background-color: rgba(26, 27, 234, 0.0750929);
//   color: $primary-color;
// }

// .mat-mdc-unelevated-button.mat-default {
//   border-radius: 22.5px;

//   font-weight: normal;
//   background-color: rgba(166, 172, 185, 0.121558);
//   color: #3f434c;
// }

// .mat-mdc-unelevated-button.mat-delete {
//   border-radius: 22.5px;

//   font-weight: normal;
//   background-color: transparent;
//   color: white;
// }

// .mat-mdc-unelevated-button.mat-cancel {
//   font-weight: normal;
//   background-color: transparent;
//   color: #a8aebe;
//   border: 1px solid #a8aebe;
//   border-radius: 17.5px;
// }

// .mat-mdc-unelevated-button.mat-vault {
//   border: 1px solid #7c7d7e;
//   background-color: transparent;
//   border-radius: 20px;
//   color: #7c7d7e;
// }

// .mat-mdc-unelevated-button.mat-vault-delete {
//   border: none;
//   background: rgba(124, 125, 126, 0.1);
//   border-radius: 20px;
//   color: #7c7d7e;
// }

// .mat-mdc-outlined-button.mat-delete {
//   border-radius: 22.5px;

//   font-weight: normal;
//   background-color: transparent;
//   color: white;
//   border-color: white;
// }

// .mat-mdc-outlined-button.mat-ternary {
//   border-radius: 5px;

//   font-weight: normal;
//   background-color: transparent;
//   color: #979eb0;
//   border-color: #979eb0;
// }

// .mat-mdc-outlined-button.mat-add {
//   border-radius: 22.5px;
//   font-weight: normal;
//   background-color: transparent;
//   color: black;
//   border-color: #dee0e4;
//   padding: 4px 12px 5px 12px;
// }

// .mat-mdc-outlined-button.mat-filter {
//   border-radius: 8px;
//   background-color: transparent;
//   color: $primary-color;
//   border: 1px dashed rgba(26, 27, 234, 0.32);
//   padding: 0 12px;
// }

// .mat-mdc-outlined-button.mat-primary {
//   border-radius: 22.5px;

//   font-weight: normal;
//   border-color: $primary-color;
//   background-color: white;
// }
// .mat-mdc-outlined-button.mat-warn {
//   border-radius: 22.5px;

//   font-weight: normal;
//   border-color: $warn-color;
//   background-color: white;
// }

// .mdc-button {
//   .mdc-button__label {
//     display: flex;
//     flex-flow: row nowrap;
//     align-items: center;
//     column-gap: 6px;
//   }
// }
