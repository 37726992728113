@import '../../vars.scss';

.custom-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 14px;
    border: 1px solid $border-default;
  }

  .mdc-dialog__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid $border-default;
    margin: 0;

    &::before {
      display: none;
    }
  }

  .mdc-dialog .mdc-dialog__content {
    padding: 25px;
    background: $background-main;
    height: 100%;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid $border-default;

    button {
      flex: 1;
    }
  }
}
