.mat-mdc-progress-bar.custom-progress-bar {
  .mdc-linear-progress__buffer-dots {
    animation-direction: reverse;
  }
  // &--accent {
  //   .mdc-linear-progress__bar-inner {
  //     border-color: $accent-color;
  //   }

  //   .mdc-linear-progress__buffer-bar {
  //     background-color: $accent-color;
  //     opacity: 0.2;
  //   }
  // }

  // &--warn {
  //   .mdc-linear-progress__bar-inner {
  //     border-color: $warn-color;
  //   }

  //   .mdc-linear-progress__buffer-bar {
  //     background-color: $warn-color;
  //     opacity: 0.2;
  //   }
  // }

  // &--success {
  //   .mdc-linear-progress__bar-inner {
  //     border-color: #23d696;
  //   }
  // }
}
