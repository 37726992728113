@import '../../vars.scss';

// Search

.mat-select-search-input {
  border-radius: 7px;
  padding: 14px 12px 14px 48px !important;
  margin: 8px 0 0px 0;
  border: none;
  background: url('/assets/img/icons/search.svg') no-repeat 12px 10px, $background-main !important;
  color: $grey-1;

  &::placeholder {
    color: $grey-3;
  }

  &:hover {
    border: 1px solid $border-default !important;
    background: url('/assets/img/icons/search.svg') no-repeat 12px 10px, $background-grey !important;
  }

  &:focus-visible {
    border: 1px solid $secondary-color !important;
    background: url('/assets/img/icons/search-active.svg') no-repeat 12px 10px, white !important;
  }
}

.mat-select-search-clear {
  display: none !important;
}

// Variant

.custom-select-variant-panel {
  min-width: 443px !important;
}

.mat-mdc-select.custom-select-variant {
  border-radius: 4px;
  padding: 7px 6px;
  width: fit-content;

  .mat-mdc-select-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down-active.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 32px;
    overflow: visible;
  }

  .mat-mdc-select-min-line {
    font-size: 25px;
    color: $primary-color;
  }

  &:hover {
    background: #eeebff;
  }

  &--active {
    background: #dcd8ff;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up-active.svg') no-repeat 0px 9px;
    }
  }
}

// Custom

.mat-mdc-select.custom-select {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 5px 14px;

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-value {
    height: 18px;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active {
    border: 1px solid $primary-color !important;
    outline: 3px solid $ternary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
    outline: 3px solid $stroke-outline;
  }
}

div.mat-mdc-select-panel {
  min-width: 152px;
  margin: 16px 0px 0px -16px;
  padding: 6px !important;
  border-radius: 10px !important;
  border: 1px solid $border-default !important;
  background: #fff !important;
  box-shadow: 0px 10px 13px 0px rgba(17, 16, 33, 0.1) !important;

  .mat-mdc-option.mdc-list-item {
    border-radius: 5px;
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    min-height: 38px;

    &:hover {
      background: $background-grey-2;
      color: $grey-1;

      .language-option {
        color: $grey-1;
      }
    }

    &.mat-mdc-option-active {
      color: $grey-1;
      background: #eeebff;

      .language-option {
        color: $grey-1;
      }
    }
  }
}

.language-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 12px;
}

.mat-pseudo-checkbox-full {
  border-radius: 6px !important;
  border: 1px solid #808594 !important;
}

// Subtype

.subtype-select-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 4px;
  height: 18px;
  border-radius: 4px;
  outline: 1px solid $border-default;
  padding: 5px 6px;
  background: white;
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 12px;
  color: $grey-3;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon {
    width: 8px;
    height: 8px;
    background: url('/assets/img/icons/plus-grey.svg') no-repeat center;
  }

  &:hover {
    color: $grey-2;

    .icon {
      background: url('/assets/img/icons/plus.svg') no-repeat center;
    }
  }

  &--filled {
    background: $background-grey;
    color: $grey-2;
    justify-content: space-between;

    .icon {
      background: url('/assets/img/icons/select-arrow-down-black.svg') no-repeat center;
    }

    &:hover {
      background: white;

      .icon {
        background: url('/assets/img/icons/select-arrow-down-black.svg') no-repeat center;
      }
    }
  }
}

// Warnings
.mat-mdc-select.custom-select-warnings {
  border: 1px solid $border-default;
  background: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  padding: 5px 14px;

  .mat-mdc-select-trigger {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;
  }

  .mat-mdc-select-placeholder {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
    color: $grey-2;
  }

  .mat-mdc-select-arrow-wrapper {
    width: 13px;
    background: url('/assets/img/icons/select-arrow-down.svg') no-repeat 0px 9px;
  }

  .mat-mdc-select-arrow {
    opacity: 0;
  }

  .mat-mdc-select-min-line {
    font-family: 'Roobert-Medium', sans-serif;
    font-size: 14px;
  }

  &:hover {
    border: 1px solid $border-color;
  }

  &--active {
    border: 1px solid $primary-color !important;
    outline: 3px solid $ternary-color !important;

    .mat-mdc-select-arrow-wrapper {
      background: url('/assets/img/icons/select-arrow-up.svg') no-repeat 0px 9px;
    }
  }

  &:focus {
    border: 1px solid $stroke-focused;
    outline: 3px solid $stroke-outline;
  }
}
