$primary-color: #503bff;
$primary-color-light: #d8d5fc;
$primary-color-lighter: #e7e6fc;
$primary-color-lightest: #f5f5ff;

$secondary-color: #8678ff;
$ternary-color: #c4bffc;

$accent-color: #ff9800;
$warn-color: #ef2e39;
$success-color: #00b071;

$background-success: #d8fff0;
$background-warning: #ffeab9;
$background-error: #ffeef0;
$background-grey: #eff0f3;
$background-grey-2: #e6e8ed;
$background-grey-3: #e3e5ea;
$background-main: #f8f9fc;
$background-error-label: #ffdce4;
$background-tooltip: #111021;

$text-success: #00492f;
$text-warning: #c34f00;
$text-error: #b20000;
$text-error-label: #930000;

$grey-1: #1e2029;
$grey-2: #3b414f;
$grey-3: #808594;

$text-grey-2: #40434b;
$text-grey-3: #8b8e97;

$border-color: #adaeb7;
$border-default: #d4d8e1;

$stroke-focused: #91c2e4;
$stroke-outline: #b3e3ff;

$waltio-primary-palette: (
  50: $primary-color,
  100: $primary-color,
  200: $primary-color,
  300: $primary-color,
  400: $primary-color,
  500: $primary-color,
  600: $primary-color,
  700: $primary-color,
  800: $primary-color,
  900: $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$waltio-warn-palette: (
  50: $warn-color,
  100: $warn-color,
  200: $warn-color,
  300: $warn-color,
  400: $warn-color,
  500: $warn-color,
  600: $warn-color,
  700: $warn-color,
  800: $warn-color,
  900: $warn-color,
  A100: $warn-color,
  A200: $warn-color,
  A400: $warn-color,
  A700: $warn-color,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
