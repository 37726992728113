@import '../../vars.scss';

.mat-mdc-tab-group.custom-tab-group {
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;

  .mat-mdc-tab-header {
    padding: 0 25px;
    border-bottom: 1px solid $border-default;
    margin-bottom: 14px;
  }

  &.transaction-panel-tab-group {
    & .mat-mdc-tab-labels {
      gap: 50px;
    }

    & .mat-mdc-tab {
      min-width: fit-content;
    }

    &.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
      flex-grow: unset;
      padding-left: 0;
      padding-right: 0;
    }

    .mat-mdc-tab-header {
      background: $background-main;
      margin-bottom: 0;
    }
  }

  & .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

// .mat-mdc-tab-group.transactions-tab-group {
//   // .mat-mdc-tab-labels {
//   //   border-bottom: 1px solid $border-default;
//   // }

//   .mat-mdc-tab:not(.mdc-tab--stacked) {
//     height: 56px;
//   }

//   .mdc-tab {
//     padding-right: 20px;
//     padding-left: 20px;
//     flex-grow: 0 !important;
//     border-right: 1px solid $border-default;

//     p {
//       color: $grey-3;
//     }

//     &:not(.mdc-tab--active) {
//       border-bottom: 1px solid $border-default;
//     }

//     &:hover {
//       background: white;
//     }

//     &:hover:not(.mdc-tab--active) {
//       p {
//         color: $grey-2;
//       }

//       .mdc-tab-indicator__content {
//         opacity: 1;
//       }

//       .mdc-tab-indicator__content--underline {
//         border-color: #cbc3ff;
//       }
//     }

//     &--active {
//       border-bottom: none;

//       p {
//         color: $grey-1;
//       }
//     }

//     .mdc-tab-indicator__content--underline {
//       border-radius: 0px 0px 5px 5px;
//       border-top-width: 6px;
//     }
//   }

//   .mdc-tab-indicator {
//     height: 6px;
//   }
// }
