.mat-mdc-radio-button.custom-radio-button {
  // COMPONENT : payment
  &.custom-payment-option {
    cursor: pointer;
    border: 1px solid #dadada;
    border-radius: 10px;
    padding: 24px 20px;
    flex: 1;

    .mdc-label {
      display: flex;
      flex-flow: row-reverse nowrap;
      align-items: center;
      justify-content: space-between;

      img {
        margin: 0 12px;
      }
    }

    &--selected {
      border: 1px solid $primary-color;
      box-shadow: 0 0 0 3px #babaf6;
    }
  }

  // COMPONENT : payment
  &.custom-plan-option {
    .mdc-radio {
      margin-top: -6px;
      align-self: baseline;
    }
  }
}
