mat-expansion-panel.custom-expansion-panel {
  .mat-expansion-panel-header {
    height: 75px;
    color: black;
  }

  .mat-expansion-panel-header-title {
    display: unset;
  }

  .mat-expansion-panel-header-description {
    font-style: italic;
    font-size: 12px;
    justify-content: center;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-content {
    justify-content: space-between;
    align-items: center;
    overflow: visible;
  }
}
