@import '../../vars.scss';

table {
  width: 100%;
}

.mat-mdc-no-data-row {
  .mat-mdc-cell {
    border: none;
    padding: 0 20px 0 26px;
  }
}

.mat-mdc-table.transactions-table {
  --mat-table-header-container-height: 41px;
  --mat-table-row-item-container-height: 62px;

  .mat-sort-header-container {
    justify-content: flex-end;
  }

  .mdc-data-table__cell:not(:first-child):not(:last-child),
  .mdc-data-table__header-cell:not(:first-child):not(:last-child) {
    padding: 0 6px;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 20px 0 26px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 26px 0 20px;
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert', sans-serif;
    font-size: 13px;
    color: $grey-3;
  }

  .mat-column-select {
    width: 70px;
  }

  .mat-column-type {
    .transaction-type {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 12px;

      &__content {
        display: flex;
        flex-flow: column nowrap;
        row-gap: 4px;
      }

      app-subtype-select {
        position: absolute;
        margin-left: 28px;
      }
    }
  }

  .mat-column-transactionDate {
    p.body-12-med {
      color: $grey-3;
    }
  }

  .mat-column-platform {
    p.body-12-med {
      color: $grey-3;
    }
  }

  .mat-column-fromAmount {
    text-align: right;
    padding: 0 0 0 6px !important;
  }

  .mat-column-trade {
    width: 34px;
    padding: 0 !important;
  }

  .mat-column-toAmount {
    padding: 0 6px 0 0 !important;
  }

  .mat-column-transferPrice,
  .mat-column-actions {
    text-align: right;
  }

  .mat-mdc-row {
    &:hover {
      background: #f7f6ff;

      .subtype-select-btn {
        outline: 3px solid #c5beff;
      }
    }
  }
}

.mat-mdc-table.accounts-table {
  --mat-table-row-item-container-height: 74px;

  background: white;
  border: 1px solid #e0e0e0;
  border-spacing: 0 4px;
  border-radius: 7px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);

  .mdc-data-table__cell:first-child {
    padding: 0 16px 0 25px;
  }

  .mdc-data-table__cell:last-child {
    padding: 0 25px 0 16px;
  }

  .mat-mdc-cell {
    background: unset;
  }

  .mat-column-name {
    width: 25%;

    .name {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;

      img {
        border-radius: 50%;
        outline: 1px solid #f0f0f1;
      }
    }

    .account-infos {
      display: flex;
      flex-flow: column nowrap;
      row-gap: 4px;

      &__name {
        padding: 7px 6px;
        border-radius: 4px;

        &:hover {
          background: $background-grey;
        }
      }

      &__name--default {
        padding: 7px 6px;
        border-radius: 4px;
      }

      p.body-14-med {
        color: $grey-1;
      }

      p.body-13 {
        padding-left: 6px;
        color: $grey-3;
      }
    }
  }

  .mat-column-actions {
    text-align: end;
  }
}

.mat-mdc-table.unknown-prices-table {
  --mat-table-header-container-height: 46px;
  --mat-table-row-item-container-height: 54px;

  background: white;
  border: 1px solid #e0e0e0;

  .mat-sort-header-container {
    justify-content: flex-end;
  }

  .mdc-data-table__cell:nth-child(2),
  .mdc-data-table__header-cell:nth-child(2) {
    padding: 0 16px 0 0;
  }

  .mdc-data-table__cell:first-child,
  .mdc-data-table__header-cell:first-child {
    padding: 0 10px 0 6px;
  }

  .mdc-data-table__cell:last-child,
  .mdc-data-table__header-cell:last-child {
    padding: 0 26px 0 20px;
  }

  .mat-mdc-header-cell {
    font-family: 'Roobert', sans-serif;
    font-size: 13px;
    color: $grey-3;
  }

  .mat-column-bar {
    width: 5px;

    .bar {
      width: 5px;
      border-radius: 10px;
      background: #f7931a;
      height: 41px;

      &--success {
        background: $success-color;
      }
    }
  }

  .mat-column-token {
    .token {
      img {
        margin-right: 5px;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 7px;

      p.body-14 {
        color: $grey-3;
      }
    }
  }

  .mat-column-price {
    .price {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 6px;
      padding: 2px 6px;
      width: fit-content;
    }

    .price-warning {
      border-radius: 4px;
      border: 1px solid rgba(195, 79, 0, 0.1);
      background: $background-warning;
    }
  }

  .mat-column-balance {
    text-align: right;
  }

  .mat-mdc-row {
    &:hover {
      background: #f7f6ff;
    }
  }

  .scam-token {
    .price,
    .volume,
    .balance {
      opacity: 0.5;
    }

    .token img {
      opacity: 0.5;
    }
  }
}
