.mat-mdc-menu-panel.custom-menu {
  border-radius: 10px;
  border: 1px solid $border-default;
  width: 221px;
  margin: 0 0 14px 14px;
  box-shadow: none !important;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .menu {
    padding: 6px;

    .item {
      display: block;
      text-decoration: none;
      cursor: pointer;
      border-radius: 5px;
      color: $grey-2;
      padding: 14px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 12px;

      &:hover {
        background: $background-main;

        p {
          color: $grey-1;
        }
      }

      .sponsorship-chip {
        background: $background-success;
        color: $text-success;
        padding: 1px 4px;
        font-size: 12px;
        border-radius: 3px;
      }
    }

    .action {
      cursor: pointer;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 11px;

      p {
        color: $grey-3;
      }

      &__icon-logout {
        width: 20px;
        height: 20px;
        background: url('/assets/img/icons/logout.svg') no-repeat center center;
      }

      &__icon-delete {
        width: 20px;
        height: 20px;
        background: url('/assets/img/icons/delete.svg') no-repeat center center;
      }

      &:hover {
        background: $background-error;
        p {
          color: $text-error-label;
        }

        .action__icon-logout {
          background: url('/assets/img/icons/logout-active.svg') no-repeat center center;
        }

        .action__icon-delete {
          background: url('/assets/img/icons/delete-active.svg') no-repeat center center;
        }
      }
    }
  }
}

// //*********************
// // CUSTOMIZE SPECIFIC MENU
// //*********************

// // COMPONENT : layout
// .custom-menu.profile-menu {
//   .disconnect {
//     display: flex;
//     flex-flow: row nowrap;
//     align-items: center;
//     background: #f5f5fa;
//     border-radius: 4px;
//     margin: 12px;
//     padding-left: 8px;

//     &__cta {
//       font-family: 'Roobert-Medium', sans-serif;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 21px;
//       color: #8c8e93;
//       margin-left: 8px;
//       padding: 0;
//     }
//   }
// }

// // COMPONENT : report-page
// .mat-mdc-menu-panel.report-menu {
//   border-radius: 8px;
//   min-height: unset;

//   .mat-mdc-menu-item {
//     padding: 8px 12px;
//     height: 34px;
//     line-height: 0;
//   }

//   .mat-mdc-menu-content:not(:empty) {
//     padding: 0;
//   }
// }
