@import '../../vars.scss';

.mat-mdc-form-field.custom-field {
  background: white;
  border: 1px solid $border-default;
  border-radius: 7px;
  height: 36px;

  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field-infix {
    min-height: 0;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-ripple {
    display: none !important;
  }

  button:hover {
    svg {
      fill: $primary-color;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 36px;
      padding: 6px 12px 6px 0;
    }
  }
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
