@import '../../vars.scss';

* {
  --mdc-checkbox-state-layer-size: 14px;
}

.mat-mdc-checkbox.custom-checkbox {
  &:focus-within {
    .mdc-checkbox__background {
      border: 1px solid $stroke-focused !important;
      outline: 3px solid $stroke-outline !important;
    }
  }

  .mat-mdc-checkbox-touch-target {
    width: 14px;
    height: 14px;
  }

  .mdc-checkbox__background {
    border: 1px solid $grey-3;
    border-radius: 6px;
  }

  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox__checkmark {
    width: 60%;
    top: 3px;
    left: 3px;
  }
}
